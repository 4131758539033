<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      :is-full-screen="false"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <span>PROMO CODES</span>
            <v-spacer></v-spacer>
            <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="1000px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider">
                    <v-card-title>
                      <span class="headline">Add Page Contributors</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="Name"
                                label="Name"
                                outlined
                            ></v-text-field>                          </v-col>
                              <v-col cols="12" sm="12" md="12">
                            <v-text-field
                                v-model="Title"
                                label="Title"
                                outlined
                            ></v-text-field>                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <div id="app">
                              <vue-editor v-model="Value"></vue-editor>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-flex>  -->
            <!-- <v-flex pt-5 xl3 lg3 md3 pb-2 sm3 xs3 px-5>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                  >
                    Delete
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete this Careers?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogDelete = false"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItem()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex> -->
            <v-btn outlined color="green" to="/newPromocode">
              add and view promocodes
            </v-btn>
          </v-card-title>
          <div>
            <v-row wrapn class="px-3 pt-5">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="couponData.code"
                  label="Promo Code"
                  :rules="Rules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="couponData.discountPercentage"
                  label="Discount Percentage"
                  :rules="Rules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-layout pt-5 pb-5 wrap justify-end>
              <v-flex xs6 md2 v-if="couponData">
                <v-btn
                  block
                  tile
                  color="green"
                  dark
                  :ripple="false"
                  style="text-transform: none"
                  depressed
                  @click="edit()"
                >
                  Save Changes
                </v-btn>
              </v-flex>
              <v-flex v-else>
                <v-btn outlined color="red" @click="add()"> Add </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <!-- <div v-else>
            <h4 style="padding-top: 30px">No data avilable.....</h4>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import { VueEditor } from "vue2-editor";
export default {
  components: {
    // VueEditor,
  },
  props: ["Name"],
  data() {
    return {
      checkbox: true,
      ServerError: false,
      file: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      code: null,
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      dialog: false,
      editdialog: false,
      couponData: {
        discountPercentage: null,
        code: null,
      },
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      Rules: [(value) => !!value || "Required."],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/coupen/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          Name: this.Name,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.couponData = response.data.data[0];
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    add() {
      axios({
        url: "/coupen/add",
        method: "POST",
        data: this.couponData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.getData();
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.$emit("stepper", {
            ServerError: true,
          });
          console.log(err);
        });
    },
    deleteItem() {
      var data = {};
      data["id"] = this.staticArray._id;
      axios({
        url: "/coupen/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialogDelete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    edit() {
      this.appLoading = true;

      this.couponData.id = this.couponData._id;

      axios({
        method: "POST",
        url: "/coupen/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.couponData,
      })
        .then((response) => {
          this.appLoading = false;
          this.editdialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
